import React from 'react'

const privacy = () => {
    return (
        <div className='bg-light px-5 treb'>
            <div className='p-5'>
                <h2 className=''>Политика за поверителност</h2>
                <br />
                <h3>Чл. 1 Общи разпоредби</h3><br />
                <p>
                    В <a href='https://macachallenge.com'>https://macachallenge.com</a> поемаме ангажимент за осигуряването на
                    поверителност и защитата на личните Ви данни, в качеството ни на
                    Администратор на личните данни, които ни предоставяте.
                </p>
                <p>
                    Тази Политика обяснява кога и защо събираме лична информация („лична
                    информация“ и „лични данни“ са използвани като идентични термини в тази
                    Политика) за хората, които посещават сайта ни и ползват услугите ни, как я
                    използваме, условията, при които можем да я разкриваме на други и как я
                    пазим.
                </p>
                <p>
                    Можем да променяме тази Политика в определени моменти, така, че молим
                    да се уверите, че сте запознати с актуалната Политика за поверителност. Като
                    използвате сайта ни и предоставяните чрез него услуги, Вие се съгласявате да
                    сте обвързани от тази политика.
                </p>
                <p>
                    Всякакви въпроси, свързани с тази Политика могат да бъдат изпращани на e-
                    mail: info@macachallenge.com
                </p><br />
                <h3>Чл. 2 За нас</h3>
                <p>
                    Ние сме <a href='https://macachallenge.com'>https://macachallenge.com</a>, и предоставяме услуги, продукти или обща
                    информация относно предмета ни на дейност.
                </p>
                <p>
                    Адресът ни на управление София, ул. “Черни Лом” №10 Когато използваме
                    „ние“ и „нас“ и глаголи в тази форма в текста по-долу, имаме
                    предвид <a href='https://macachallenge.com'>https://macachallenge.com</a>
                </p><br />
                <h3>Чл. 3 Как събираме лична информация от Вас</h3>
                <p>
                    Получаваме информация за Вас, когато използвате сайта
                    ни <a href='https://macachallenge.com'>https://macachallenge.com</a>, например когато изпращате запитване за
                    получаване на оферта/информация, или в процеса, при който заявявате,
                    поръчвате и ползвате предоставяните на сайта услуги/продукти.
                </p><br />
                <h3>Чл. 4 Какъв вид лична информация събираме</h3>
                <p>Събираме само основни лични данни за Вас, които не включват специални
                    категории лични данни („чувствителни данни“). Личната информация, която
                    събираме може да включва името, адреса, e-mail адреса, телефонния номер,
                    други данни, изисквани от Закона за счетоводството и други закони, както и
                    информация относно това кои страници са посетени и кога.
                </p><br />
                <h3>Чл. 5 Защо имаме нужда от Вашите данни</h3>
                <p>
                    Имаме нужда да знаем Вашите лични данни, за да Ви предоставим
                    продуктите/услугите, предлагани чрез този сайт, в рамките на възникнал
                    договор между нас във връзка с предоставянето им, както и информация,
                    която сте поискали или за която изрично сте се съгласили. Ние няма да
                    събираме лични данни, от които нямаме нужда за горепосочените цели.
                </p><br />
                <h3>Чл. 6 Как се използва Вашата информация</h3>
                <p>Можем да използваме Вашата информация, за да:</p>
                <ul>
                    <li>
                        Предоставяме продуктите/услугите, които сте заявили, включително
                        да обработваме поръчките Ви и да изпълняваме задълженията си,
                        възникнали от договорните отношения между нас;
                    </li>
                    <li>
                        Предоставяме нова информация, пряко свързана с продукти/услуги,
                        които сте заявили;
                    </li>
                    <li>
                        Отговаряме на запитвания и да Ви предоставяме информация, която
                        сте поискали, включително помощ за решаването на въпроси,
                        възникнали във връзка с Ваши запитвания;
                    </li>
                    <li>
                        Използваме ре-маркетинг през доверени сайтове, включително Meta
                        Ads, Google Ads и други;
                    </li>
                    <li>
                        Търсим вашите мнения или коментари относно предоставяните от
                        нас услуги и продукти/услуги;
                    </li>
                    <li>
                        Правим статистически анализи и други анализи за подобряване на
                        продуктите/услугите ни;
                    </li>
                    <li>
                        Осигурим запазване на Ваши интереси във връзка с ползването на
                        сайта или продуктите/услугите ни;
                    </li>
                    <li>
                        Ви информираме за промени в общите условия, политиката за
                        поверителност или продуктите/услугите ни.
                    </li>
                </ul>
                <br />
                <h3>Чл. 7 Колко дълго пазим Вашата информация</h3>
                <p>Пазим Вашите лични данни само доколкото и докато това е необходимо за
                    съответната дейност, за която са предоставени, или колкото е предвидено в
                    договор между нас, при спазване на изискванията и сроковете за това,
                    предвидени в Закона за счетоводството, данъчните закони и другите
                    приложими действащи нормативни актове в Република България.
                </p>
                <p>
                    Данните, които сте предоставили за използване за маркетингови цели, се
                    пазят докато ни уведомите, че не желаете повече да получавате съответната
                    информация от нас.
                </p>
                <p>
                    След постигане на целите на обработката на Вашите лични данни, ние ги
                    унищожаваме.
                </p><br />
                <h3>Чл. 8 Кой има достъп до Вашата информация</h3>
                <p>Няма да продаваме, предоставяме под наем, нито ще споделяме за
                    маркетингови цели Вашата информация с трети лица.</p>
                <p>Можем да предаваме информацията Ви на трети лица – доставчици на услуги,
                    подизпълнители и други свързани организации единствено за целите на
                    извършването на задачи и предоставянето на услуги за Вас от наше име.</p>
                <p>Независимо от това, когато използваме трети лица – доставчици на услуги,
                    ние разкриваме само личната информация, която е необходима за
                    предоставянето на услугата, и имаме договор със съответното трето лице,
                    който изисква от него да пази Вашата информация и да не я използва за свои
                    преки маркетингови цели.</p>
                <p>
                    Ние няма да предоставяме информацията Ви на трети лица за техни
                    маркетингови цели, освен ако Вие сте поискали това, или е необходимо по
                    силата на закон, съдебен акт или за предотвратяване на измама или друго
                    престъпление.
                </p>
                <p>
                    Работим с различни трети лица – доставчици на услуги, за да Ви осигурим
                    качествени и надеждни продукти и/или услуги. Когато проучвате за някоя от
                    тези продукти и/или услуги или закупите такава, съответното трето лице -
                    доставчик на продукти и/или услуги ще използва Вашите данни, за да Ви
                    предостави информация и да изпълни задълженията си, които възникват в
                    тази връзка по договори с тях. В някои случаи те ще действат като
                    администратор на данни на Вашата информация, и затова Ви препоръчваме
                    да се запознаете с тяхната Политика за поверителност. Тези трети лица –
                    доставчици на продукти и/или услуги ще споделят Вашата информация с нас,
                    като ние ще я използваме в съответствие с тази Политика за поверителност.
                </p>
                <p>
                    Предоставяне на Ваши данни на държавни органи може да се извърши само в
                    предвидените от закона случаи и в обем, който не надвишава целите, за които
                    са поискани.
                </p>
                <p>
                    Комисията за защита на личните данни упражнява законовия контрол върху
                    процеса по обработването на лични данни и ние осигуряваме пълен достъп до
                    водените от нас регистри за лични данни при законовите условия за това.
                </p>
                <h3>Чл. 9 Къде обработваме Вашите данни</h3>
                <p>
                    Всички лични данни, които обработваме се обработват в България, за целите
                    на хостинга и поддръжката - тази информация се намира в България. Трети
                    лица нямат достъп до Вашите лични данни, освен когато закон изисква това.
                </p>
                <h3>Чл.10 В кои случаи ще се свързваме с Вас</h3>
                <p>Вие имате избор дали да получавате информация от нас или не.</p>
                <p>
                    Ние можем да се свързваме с Вас свободно, когато и доколкото това е
                    необходимо за изпълнението на продукти и/или услугите, които сте заявили,
                    на задълженията по договор между нас, за предоставяне на нова информация,
                    пряко свързана с продуктите/услуги, които сте заявили, в отговор на Ваше
                    запитване, за целите на запазване на Ваши интереси във връзка с ползването
                    на сайта или продукти и/или услугите ни, или въз основа на законово
                    изискване, както и в случай на връщане на продукт. Ние имаме право да
                    изпращаме имейл бюлетин за новости от сайта, както продукти и/или услуги,
                    за който бюлетин сте се абонирали с имейл адреса си.<br />
                    Ние няма да се свързваме с Вас за маркетингови цели по телефон, чрез
                    текстови съобщения, или по пощата, освен ако не сте ни дали
                    предварителното си изрично съгласие, или комуникацията е пряко свързана с
                    Вас и отговаря на изискванията на Общия регламент за защита на личните
                    данни (Регламент (ЕС) 2016/679),
                </p><br />
                <h3>Чл.11 Как можете да получите достъп, коригиране или заличаване на
                    Вашите лични данни</h3>
                <p>
                    Вие имате право по всяко време да поискате от нас:
                </p>
                <ul>
                    <li>достъп до Вашите лични данни, събрани в процеса на предоставяне
                        и ползване на услугите, включително: потвърждение дали
                        обработваме Ваши лични данни, какви категории са те, целите на
                        това обработване, и получателите, на които данните се разкриват,
                        както и съобщение с копие на личните Ви данни, които се
                        обработват, и техния източник;</li>
                    <li>
                        коригиране на информация, която сте заявили при нас, когато е
                        станала невярна или неактуална, напр. на email адреса си, и каквато
                        и да е друга информация;
                    </li>
                    <li>
                        заличаване или блокиране на Ваши лични данни, когато
                        обработването им не отговаря на изискванията на Закона за защита
                        на личните данни или на Общия регламент за защита на личните
                        данни (Регламент (ЕС) 2016/679);
                    </li>
                    <li>
                        уведомяване за това коригиране, заличаване или блокиране на
                        третите лица, на които са били разкрити личните Ви данни, освен
                        когато това е невъзможно или е свързано с прекомерни усилия.
                    </li>
                </ul>
                <p>
                    Желаното от Вас действие по предоставянето на достъп, коригиране,
                    заличаване или блокиране на лични данни се извършва единствено по
                    отношение на Вашите лични данни. Извършването му е безплатно.
                </p>
                <p>
                    За да поискате достъп, коригиране, заличаване или блокиране на Вашите
                    лични данни, които обработваме, е необходимо да изпратите до нас писмено
                    заявление, което съдържа:
                </p>
                <p>
                    1. име, адрес и други данни за идентифициране на съответното физическо
                    лице;
                </p>
                <p>2. описание на искането;</p>
                <p>3. предпочитана форма за предоставяне на информацията по чл. 28, ал. 1;</p>
                <p>4. подпис, дата на подаване на заявлението и адрес за кореспонденция.</p>
                <p>
                    5. нотариално заверено пълномощно, когато се подава от упълномощено
                    лице. Заявлението може да бъде подписано и с електронен подпис.
                </p>
                <p>
                    Заявлението се изпраща на email: info@macachallenge.com
                </p>
                <p>
                    Ние ще изпълним искането Ви за достъп, коригиране, заличаване или
                    блокиране в срок до 14 дни, при спазване на законовите изисквания и
                    изключения в тази връзка.
                </p>
                <p>
                    Вие ще бъдете уведомени за решението за извършване на поисканото
                    действие или отказа за това съгласно изискванията на закона. Уведомлението
                    за това ще Ви бъде изпратено по пощата с обратна разписка или може да се
                    получи лично срещу подпис.
                </p><br />
                <h3>
                    Чл.12 Как може да подадете възражение или оплакване срещу
                    обработването на лични данни
                </h3>
                <p>В предвидените от закона случаи, Вие имате право да:</p>
                <ul>
                    <li>
                        възразите пред нас срещу обработването на личните Ви данни при
                        наличие на законово основание за това; когато възражението е
                        основателно, личните данни на съответното физическо лице не
                        могат повече да бъдат обработвани;
                    </li>
                    <li>
                        да възразите срещу обработването на личните Ви данни за целите на
                        директния маркетинг;
                    </li>
                    <li>
                        да бъдете уведомени преди личните Ви данни да бъдат разкрити за
                        пръв път на трети лица или използвани от Ваше име за целите по
                        предходната точка на този член, като Ви бъде предоставена
                        възможност да възразите срещу такова разкриване или използване.
                    </li>
                    <li>
                        Ако желаете да направите каквото и да оплакване за начина, по който се
                        обработват Вашите данни, можете да се свържете с нас
                        на <a href='https://macachallenge.com'>https://macachallenge.com</a>. Ако не сте удовлетворени от нашия отговор или
                        не смятате, че обработваме данните Ви по законосъобразен начин, можете да
                        се обърнете към компетентния орган за защитата на личните данни – Комисия
                        за защита на личните данни.
                    </li>
                </ul>
                <p>
                    Адрес: гр. София 1592, бул. „Проф. Цветан Лазаров“ № 2<br />
                    тел.: 02/915 35 18, 02/915 35 15, 02/915 35 19, факс: 02/915 35 25<br />
                    E-mail: kzld@cpdp.bg<br />
                    Уеб сайт: www.cpdp.bg<br />
                </p><br />
                <h3>Чл.13 Налични мерки за сигурност за защита на Вашата информация от
                    загуба, злоупотреба или промяна</h3>
                <p>
                    Когато ни давате лична информация, ние предприемаме действия да
                    осигурим тя да бъде обработвана по сигурен начин.
                    Уебсайтът <a href='https://macachallenge.com'>https://macachallenge.com</a> ползва протокол за защитена
                    комуникация SSL сертификат. HTTPS удостоверява сайта и съответния уеб
                    сървър, на който е качен. Също така двупосочно криптира връзката между
                    клиент и сървър, което осигурява защита срещу подслушване, подправяне
                    или фалшифициране на съдържанието на съобщенията. На практика това дава
                    достатъчно гаранции, че потребителят се свързва с правилния сайт (а не
                    фалшиво копие), както и гарантира, че съдържанието на съобщенията между
                    потребителя и сайта не може да се прочете или подправи от трети страни.
                    Допълнителна информация за HTTPS (https://bg.wikipedia.org/wiki/HTTPS).
                </p>
                <p>
                    Не-чувствителна информация (Вашия email адрес, и т.н.) се предава обичайно
                    през интернет, и за това никога не може да бъде гарантирана 100% сигурност.

                    В тази връзка, макар, че се стремим да защитим Вашата информация, не
                    можем да гарантираме сигурността на информация, която ни изпращате, и
                    Вие правите това на свой риск. След като получим Вашата информация,
                    полагаме максимални усилия да осигурим сигурността й на нашите системи.
                </p>
                <br />
                <h3>Чл.14 Профилиране</h3>
                <p>Няма да използваме автоматично вземане на решения или профилиране.</p><br />
                <h3>Чл. 15 Използване на „бисквитки“(‘cookies’)</h3>
                <p>
                    Като много други уеб-сайтове, така и нашият използва бисквитки.
                    „Бисквитките“ са малки обособени пакети информация, изпращана от дадена
                    организация към Вашия компютър, за да Ви разпознае при Ваше посещение.
                    Те събират статистически данни за действията Ви в браузъра. Това ни
                    позволява да проследим модели на потребителския трафик, както и да
                    разработваме статистически анализи за използването на услугите, като
                    времето, прекарвано на уеб-сайта и страниците, което се посещават най-
                    често. Бисквитките не Ви идентифицират като личност, а обобщените
                    статистически данни не включват лична информация. Бисквитките ни
                    помагат да подобрим уеб-сайта и да предоставяме по-добра персонализирана
                    услуга.
                </p>
                <p>
                    Съгласявайки се да използвате този сайт и услугите му, Вие изразявате
                    съгласие за използването на „бисквитки“, включително бисквитките на
                    Google Analytics.
                </p>
                <p>
                    Ако предпочитате да не получавате „бисквитки“ докато разглеждате
                    Уебсайта или чрез HTML форматирани имейли, бихте могли да ги откажете.
                    За целта можете да настроите интернет браузъра си да предупреждава преди
                    да приеме бисквитка или да откажете бисквитки, когато Ви сигнализира за
                    наличието им. Обикновено можете да намерите тези настройки в меню
                    ‘Опции’ или ‘Предпочитания’ на вашия браузър. За да сте наясно с тези
                    настройки, както и за по-подробна информация, можете да използвате бутона
                    ‘Помощ’ от менютата на Вашия браузър.
                </p>
                <br /><h3>Чл.16 Линкове към други сайтове</h3>
                <p>
                    Нашият уеб-сайт може да съдържа линкове към други уеб-сайтове,
                    управлявани от други организации. Тази политика за поверителност се
                    прилага само за нашия сайт, така, че Ви насърчаваме да прочетете
                    изявленията за поверителност на другите уеб-сайтове, които посещавате. Ние
                    не отговаряме за политиката за сигурност на други уеб-сайтове, дори ако
                    използвате линкове от нашия сайт, за да достигнете до тях.
                </p>
                <p>
                    Наред с това, ако сте попаднали на нашия уеб-сайт чрез сайта на трето лице,
                    ние не можем да носим отговорност за политиката за поверителност и
                    практиките на собствениците и администраторите на сайта на това трето лице
                    и Ви препоръчваме да проверите неговата Политика за поверителност.
                </p><br />
                <h3>Чл. 17 Прехвърляне на лична информация извън Европа</h3>
                <p>
                    Като част от услугите, които Ви предоставяме, информацията, която ни
                    предоставяте може да бъде прехвърляна до страни извън Европейския съюз
                    („ЕС“). Тези страни може да нямат подобни закони за защита на личните
                    данни като България. Като предоставяте личните си данни, Вие се
                    съгласявате с това прехвърляне, съхранение, или обработване. Ако
                    прехвърляме Ваша информация извън ЕС по този начин, ние ще вземем
                    подходящи мерки за сигурност, за да осигурим защитата на личната Ви
                    информация в съответствие с тази Политика за поверителност.
                    Ако използвате услугите ни докато сте извън ЕС, Вашата информация може
                    да бъде прехвърляна извън ЕС, за да Ви бъдат предоставени тези услуги.
                </p><br />
                <h3>Чл.18 Преразглеждане на тази Политика</h3>
                <p>
                    Преглеждаме редовно тази Политика, за да осигурим качествена и
                    законосъобразна защита на Вашите лични данни. Тази Политика е последно
                    актуализирана през февруари 2024г. и е в съответствие с Общ регламент за
                    защита на личните данни (Регламент (ЕС) 2016/679).
                </p>
            </div>
            <div className='container text-center pb-5'>
                <a href='/' className='btn btn-dark px-4'> Назад</a>
            </div>
        </div>
    )
}

export default privacy