import React, { useState, useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import "./cust.css";
import macalogo from "./images/maca.new.png";
import box from "./images/products image2-min.png";
import Footer from "./Footer";
import box2 from "./images/products image.png";
import MacachalRepository from "../data/macachal_repository";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Modal from "react-bootstrap/Modal";

const manicureCardsSKU = "MCC1";
const deliveryOptionType = {
  home: "home",
  econtOffice: "econtOffice",
};

const Cart = () => {
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [count, setCount] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState(null);
  const [econtCities, setEcontCities] = useState(null);
  const [showOrderForm, setShowOrderForm] = useState(false); // Initially false
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState(deliveryOptionType.home);
  const [econtOfficeAddress, setEcontOfficeAddress] = useState(null);
  const [econtOffices, setEcontOffices] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isOrdering, setIsOrdering] = useState(false);

  const macachalRepository = new MacachalRepository();
  const navigate = useNavigate();

  const fetchEcontCitiesInBulgaria = () => {
    macachalRepository
      .getEcontCitiesInBulgaria()
      .then((data) => {
        let cities = data.map((city) => ({
          value: city.id,
          label: city.name,
        }));

        setEcontCities(cities);
      })
      .catch((error) => {
        console.log(error);
        showAlertError(
          "Грешка при изтегляне на населени места.",
          "Моля презаредете страницата."
        );
        Sentry.captureException(error);
      });
  };

  const fetchEcontOfficesInBulgariaByCityId = (cityId) => {
    macachalRepository
      .getEcontOfficesInBulgariaByCityId(cityId)
      .then((data) => {
        let offices = data.map((office) => {
          let officeNameAndFullAddress = `${office.name} (${office.fullAddress})`;

          return {
            value: officeNameAndFullAddress,
            label: officeNameAndFullAddress,
          };
        });

        setEcontOffices(offices);
      })
      .catch((error) => {
        console.log(error);
        showAlertError(
          "Грешка при изтегляне на офиси на Еконт.",
          "Моля презаредете страницата."
        );
        Sentry.captureException(error);
      });
  };

  const createOrder = (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    setIsOrdering(true);

    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    let addressBasedOnSelectedDeliveryOptionType =
      getAddressBasedOnSelectedDeliveryOptionType();

    macachalRepository
      .createOrder({
        id: randomNumber,
        customer_name: `${firstName} ${lastName}`,
        customer_phone: number,
        customer_email: email,
        country: "България",
        city: city.label,
        address: addressBasedOnSelectedDeliveryOptionType,
        payment_method: "Наложен платеж",
        order_total_amount: totalPriceFormatted,
        currency: "BGN",
        products: manicureCardsSKU + ":" + count,
      })
      .then(() => {
        setOrderPlaced(true);
        setShowOrderForm(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setAddress("");
        setNumber("");
        setCity(null);
        setEcontOfficeAddress(null);
        navigate("/orderPlaced");
      })
      .catch((error) => {
        console.log(error);
        showAlertError(
          "Грешка при създаване на поръчка.",
          "Моля опитайте отново."
        );
        Sentry.captureException(error);
      })
      .finally(() => {
        setIsOrdering(false);
      });
  };

  const showAlertError = (title, text) => {
    Swal.fire({
      position: "top-end",
      icon: "warning",
      toast: "true",
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 8000,
    });
  };

  const getAddressBasedOnSelectedDeliveryOptionType = () => {
    if (deliveryOption === deliveryOptionType.econtOffice) {
      return `Офис Еконт: ${econtOfficeAddress.value}`;
    } else {
      return address;
    }
  };

  let itemPrice = 49.9;

  const boxWeight = 150;
  const totalWeight = count * boxWeight;

  let deliveryPrice;
  if (deliveryOption === deliveryOptionType.home) {
    if (totalWeight <= 1000) {
      deliveryPrice = 6.9;
    } else {
      deliveryPrice = 0;
    }
  } else if (deliveryOption === deliveryOptionType.econtOffice) {
    if (totalWeight <= 1000) {
      deliveryPrice = 5.9;
    } else {
      deliveryPrice = 0;
    }
  }

  const add = () => {
    setCount(count + 1);
  };

  const remove = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeOfCart = () => {
    localStorage.removeItem("addedToCart");
    window.location.reload();
  };

  let totalPrice = itemPrice * count + deliveryPrice;
  let totalPriceFormatted = totalPrice.toFixed(2);
  let intPrice = itemPrice * count;

  const isItem = () => {
    let item = localStorage.getItem("addedToCart");

    if (item) {
      return (
        <>
          <div className="col-sm-3  ">
            <img src={box} height="300vh" alt="Product" />
          </div>
          <div className="col py-3 markf">
            <h1>
              Manicure Cards <br /> Challenge
            </h1>
            <p className="text-secondary">#MacaChallenge</p>
            <br />
            <h3 className="fw-normal">
              <i>49.90лв / брой</i>
            </h3>
            <div className="d-flex">
              <button
                className="btn text-light par-font-fam rl border"
                onClick={remove}
              >
                -
              </button>
              <input
                className="inpplc text-white border form-control rounded-0  bg-transparent"
                value={count}
                disabled
              ></input>
              <button
                className="btn text-light par-font-fam rr border"
                onClick={add}
              >
                +
              </button>
              <button className="btn btn-danger mx-3" onClick={removeOfCart}>
                {" "}
                <i className="fa-solid fa-trash-can"></i>
              </button>
            </div>
          </div>

          <div className="col-md-4 mt-3 par-font-fam">
            <div className="row">
              <div className="col-sm-7 float-left">
                <h5 className="lett markf">
                  <b>Междинна сума: </b>
                </h5>
              </div>
              <div
                className={`col-5 font-large ltr-space mb-2 ${
                  isWideScreen ? " d-flex justify-content-end" : ""
                } text-right`}
              >
                <h4 className="markf">
                  {(localStorage.getItem("addedToCart") === "added" &&
                    intPrice.toFixed(2)) ||
                    0}{" "}
                  лв
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-5 float-left">
                <p className="text-secondary font-large markf">Доставка: </p>
              </div>
              <div className="col-7 markf d-flex justify-content-end">
                <p className="text-secondary d-flex justify-content-end text-end">
                  <i>Доставката се начислява отделно</i>
                </p>
              </div>
            </div>
            <hr />
            <div className="d-block ">
              <div className="row">
                <div className="col-7 markf">
                  <h3>
                    <b>Общо:</b>
                  </h3>
                </div>
                <div className="col-5 d-flex justify-content-end markf">
                  <h3>
                    {localStorage.getItem("addedToCart")
                      ? intPrice.toFixed(2)
                      : 0}{" "}
                    лв
                  </h3>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button
                  className="button-wh-pink float-right markf w-100"
                  onClick={() => setShowOrderForm(true)}
                >
                  Поръчай
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      itemPrice = 0;
      return (
        <>
          <h2 className="markf">
            Количката е празна.{" "}
            <i className="fa-regular fa-face-angry pink"></i>
          </h2>
        </>
      );
    }
  };

  useEffect(() => {
    fetchEcontCitiesInBulgaria();

    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 600);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (orderPlaced) {
      localStorage.removeItem("addedToCart");
      window.location = "/";
    }
  }, [orderPlaced]);

  const selectNoOptionsMessage = ({ inputValue }) => (
    <div>
      Няма намерени резултати
      {inputValue === null || inputValue === "" ? "" : ` за "${inputValue}"`}
    </div>
  );

  const selectLoadingMessage = ({ _ }) => <div>Зареждане...</div>;

  const handleCityChange = (city) => {
    setCity(city);
    setAddress("");
    setEcontOfficeAddress(null);
    setEcontOffices(null);

    if (deliveryOption === deliveryOptionType.econtOffice && city !== null) {
      fetchEcontOfficesInBulgariaByCityId(city.value);
    }
  };

  const handleDeliveryOptionChange = (option) => {
    setAddress("");
    setEcontOfficeAddress(null);
    setEcontOffices(null);
    setDeliveryOption(option);

    if (option === deliveryOptionType.econtOffice && city !== null) {
      fetchEcontOfficesInBulgariaByCityId(city.value);
    }
  };

  const validateForm = () => {
    let errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]+$/;

    if (!firstName.trim()) errors.firstName = "Моля въведете име.";
    if (!lastName.trim()) errors.lastName = "Моля въведете фамилия.";
    if (!emailRegex.test(email)) errors.email = "Имейлът не е валиден.";
    if (!phoneRegex.test(number) || number.length < 10)
      errors.number = "Телефонният номер не е валиден.";
    if (city === null) errors.city = "Моля въведете населено място.";
    if (deliveryOption === deliveryOptionType.home && !address.trim())
      errors.address = "Моля въведете адрес.";
    if (
      deliveryOption === deliveryOptionType.econtOffice &&
      econtOfficeAddress === null
    )
      errors.econtOfficeAddress = "Моля изберете офис на Еконт.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------

  return (
    <div>
      <nav className="navbar navbar-dark border-bottom">
        <div className="container-fluid">
          <a className="navbar-brand mx-2" href="/">
            <img src={macalogo} height="70" alt="Maca Logo" />
          </a>
          <form className="d-flex mx-4 right">
            <div className="li">
              <a
                className="navbar-brand navbar-branda mx-3 markf"
                href="/products"
              >
                Продукти
              </a>
            </div>
            <div className="li">
              <a
                className="navbar-brand navbar-branda mx-3 markf"
                href="/about"
              >
                За нас
              </a>
            </div>
          </form>
        </div>
      </nav>
      <br />
      <br />
      <br />
      {/* --------------------- Сума и бутон ------------------ */}
      <div className="container text-light my-5 py-5">
        <div className="row p-3">{isItem()}</div>
      </div>
      <div className="bg-light">
        <Footer />
      </div>

      <Modal
        show={showOrderForm}
        onHide={() => setShowOrderForm(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="orderForm"
        centered
      >
        <Modal.Body>
          <button
            className="btn btn-danger posbtn"
            onClick={() => setShowOrderForm(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
          <form className="text-light">
            <h2 className="markf d-flex justify-content-center">Поръчка</h2>
            <hr />
            <div className="mb-3 d-flex justify-content-center">
              <h4 className="markf">
                {count}x <img src={box2} height="40" alt="Product" /> Manicure
                Cards Challenge
              </h4>
            </div>
            <div className="row mb-3">
              <div className="col-md mb-3 mb-md-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Име"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  id="firstName"
                  name="firstName"
                />
                {formErrors.firstName && (
                  <div className="error-message">{formErrors.firstName}</div>
                )}
              </div>
              <div className="col-md mb-3 mb-md-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Фамилия"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  id="lastName"
                  name="lastName"
                />
                {formErrors.lastName && (
                  <div className="error-message">{formErrors.lastName}</div>
                )}
              </div>
              <div className="col-md">
                <input
                  type="tel"
                  placeholder="Телефонен Номер"
                  className="form-control"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  id="phone"
                  name="phone"
                />
                {formErrors.number && (
                  <div className="error-message">{formErrors.number}</div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <input
                type="email"
                placeholder="Имейл"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                name="email"
              />
              {formErrors.email && (
                <div className="error-message">{formErrors.email}</div>
              )}
            </div>
            <div className="mb-3">
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Населено място"
                required="true"
                isClearable="true"
                isSearchable="true"
                name="city"
                captureMenuScroll="true"
                menuShouldBlockScroll="true"
                menuShouldScrollIntoView="true"
                loadingMessage={selectLoadingMessage}
                noOptionsMessage={selectNoOptionsMessage}
                onChange={handleCityChange}
                isLoading={econtCities === null}
                options={econtCities ?? []}
                value={city}
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    textAlign: "left",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    textAlign: "left",
                  }),
                  option: (base) => ({
                    ...base,
                    color: "black",
                    textAlign: "left",
                  }),
                }}
              />
              {formErrors.city && (
                <div className="error-message">{formErrors.city}</div>
              )}
            </div>
            <div className="mb-3 d-flex justify-content-center">
              <div className="form-check mx-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="deliveryOption"
                  id="homeDelivery"
                  disabled={!city}
                  value={deliveryOptionType.home}
                  checked={deliveryOption === deliveryOptionType.home}
                  onChange={() =>
                    handleDeliveryOptionChange(deliveryOptionType.home)
                  }
                />
                <label className="form-check-label" htmlFor="homeDelivery">
                  До адрес
                </label>
              </div>
              <div className="form-check mx-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="deliveryOption"
                  id="officeDelivery"
                  disabled={!city}
                  value={deliveryOptionType.econtOffice}
                  checked={deliveryOption === deliveryOptionType.econtOffice}
                  onChange={() =>
                    handleDeliveryOptionChange(deliveryOptionType.econtOffice)
                  }
                />
                <label className="form-check-label" htmlFor="officeDelivery">
                  До офис на Еконт
                </label>
              </div>
            </div>
            {deliveryOption === deliveryOptionType.econtOffice ? (
              <div className="mb-3">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Офис на Еконт"
                  required="true"
                  isClearable="true"
                  isSearchable="true"
                  isDisabled={!city}
                  name="econtOfficesInBulgaria"
                  captureMenuScroll="true"
                  menuShouldBlockScroll="true"
                  menuShouldScrollIntoView="true"
                  loadingMessage={selectLoadingMessage}
                  noOptionsMessage={selectNoOptionsMessage}
                  onChange={(e) => setEcontOfficeAddress(e)}
                  isLoading={econtOffices === null && city !== null}
                  options={econtOffices ?? []}
                  value={econtOfficeAddress}
                  styles={{
                    placeholder: (base) => ({
                      ...base,
                      textAlign: "left",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      textAlign: "left",
                    }),
                    option: (base) => ({
                      ...base,
                      color: "black",
                      textAlign: "left",
                    }),
                  }}
                />
                {formErrors.econtOfficeAddress && (
                  <div className="error-message">
                    {formErrors.econtOfficeAddress}
                  </div>
                )}
              </div>
            ) : (
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Адрес"
                  className="form-control"
                  disabled={!city}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  id="address"
                  name="address"
                />
                {formErrors.address && (
                  <div className="error-message">{formErrors.address}</div>
                )}
              </div>
            )}
            <hr />
            <div className="mb-2 d-flex justify-content-center">
              <h3 className="markf">Междинна сума: {intPrice.toFixed(2)}лв</h3>
            </div>
            <div className="mb-2 d-flex justify-content-center">
              <h4 className="markf text-secondary">
                Доставка: {deliveryPrice.toFixed(2)}лв
              </h4>
            </div>
            <div className="mb-2 d-flex justify-content-center">
              <h2 className="markf">Обща сума: {totalPriceFormatted}лв</h2>
            </div>
            <hr />

            <div className="d-flex justify-content-center">
              <button
                className="btn button-order markf"
                onClick={createOrder}
                disabled={isOrdering}
              >
                {isOrdering ? "Пренасочване..." : "Поръчай"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Cart;
